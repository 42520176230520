.App {
  text-align: center;
}
/* .notification-container {
  top: 0 !important;
  left: 0 !important;
} */
.profile-card {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.row {
  width: 100%;
  text-align: center;
}

.block {
  width: 5000px;
  display: inline-block; 
}